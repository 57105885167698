import axios from 'axios';
import { getCookie } from './cookies';

axios.defaults.baseURL = process.env.REACT_APP_SERVER;

console.log(process.env.REACT_APP_SERVER);

axios.interceptors.request.use(function (config) {
	const accessToken = getCookie('accessToken');
	config.headers.Authorization = `Bearer ${accessToken}`;
	return config;
});
